import axios from 'axios';


export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token')
    },
    getters: {
    },
    mutations: {
    },
    actions: {

        getJenisHewan({state}, _params) {

            const token = state.token

            return new Promise((resolve, reject) => {
                axios.get('/jenis_hewan?row=100&sortby=id&sorttype=desc&keyword=', { params: _params, data: token }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addJenisHewan({state}, data) {

            const payload = {
                ...data,
                token: state.token
            }
            return new Promise((resolve, reject) => {
                axios.post('/jenis_hewan/create', payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateJenisHewan( {state}, { id, data, user_id }) {
            const payload = {
                nama: data, 
                user_id,
                token: state.token
            }
            console.log(payload)
            return new Promise((resolve, reject) => {
                axios.put('/jenis_hewan/update/' + id, payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteJenisHewan(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/jenis_hewan/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/jenis_hewan/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/jenis_hewan/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/jenis_hewan/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getPersentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/jenis_hewan/persentase', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        importFile(_, data){
            return new Promise((resolve, reject) => {
                axios.post('/jenis_hewan/import', data).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },

}