import axios from 'axios';


export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token')
    },
    mutations: {},
    actions: {

        getListProduksi({state}, _params) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/produksi', { params: _params, token }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addListPeternak({ state }, data) {
            const payload = {
                ...data,
                token: state.token
            }

            console.log(payload)
            return new Promise((resolve, reject) => {
                axios.post('/peternak/create', payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateListPeternak( {state}, { id, data }) {

            const payload = {
                ...data,
                token: state.token
            }

            return new Promise((resolve, reject) => {
                axios.put('/peternak/update/' + id, payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteListPeternak(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/peternak/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/peternak/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport() {
            return new Promise((resolve, reject) => {
                axios.get('/produksi/selected_action/export_excel?year=2023',  { responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/peternak/selected_action/export_pdf?year=2023', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getPersentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/peternak/persentase', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
    getters: {

    }

}