import axios from 'axios';


export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token')
    },
    mutations: {},
    actions: {

        getListPeternak({state}, _params) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/peternak?row=100&sortby=id&sorttype=desc&keyword=&kecamatan&desa=', { params: _params, token }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addListPeternak({ state }, data) {
            const payload = {
                ...data,
                token: state.token
            }

            console.log(payload)
            return new Promise((resolve, reject) => {
                axios.post('/peternak/create', payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateListPeternak( {state}, { id, data }) {

            const payload = {
                ...data,
                token: state.token
            }

            return new Promise((resolve, reject) => {
                axios.put('/peternak/update/' + id, payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteListPeternak(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/peternak/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/peternak/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport(_, _params) {
            console.log("run export excel")
            return new Promise((resolve, reject) => {
                axios.get('/peternak/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/peternak/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getPersentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/peternak/persentase', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        importFile(_, data){
            return new Promise((resolve, reject) => {
                axios.post('/peternak/import', data).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
    getters: {

    }

}