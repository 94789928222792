import axios from 'axios';


export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token')
    },
    mutations: {},
    actions: {

        getKenaikanPopulasi({state}, _params) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/kenaikanpopulasi?year=', { params: _params, data: token }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getPopulasiHewanTernak({state}) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/persentasepopulasi', { data: token }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getKematianPopulasi({state}) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/kematianpopulasi', { data: token }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getPemotonganHewan({state}) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/pemotonganhewan', { data: token }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getPopulasiBerdasarkanKecamatan({state}, _params) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/populasihewanternakkecamatan', {params: _params, data: token }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getPengeluaranHewan({state}) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/pengeluaranhewan', {  data: token }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getSummaryTipePopulasi({state}) {
            const token = state.token

            return new Promise((resolve, reject) => {
                axios.get('/populasi_hewan/sumary', {  data: token }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        }
        
    },
    getters: {

    }

}