import axios from 'axios';


export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token')
    },
    mutations: {},
    actions: {

        getListKelompok( {state}, _params) {

            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get(`/kelompok?row=100&sortby=id&sorttype=desc&keyword=&kecamatan=&desa=`, { params: _params, data :token }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addListKelompok({ state }, data) {
            const payload = {
                ...data,
                token: state.token
            }

            console.log(payload)
            return new Promise((resolve, reject) => {
                axios.post('/kelompok/create', payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateListKelompok( {state}, { id, data }) {

            const payload = {
                ...data,
                token: state.token
            }

            return new Promise((resolve, reject) => {
                axios.put('/kelompok/update/' + id, payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteListKelompok(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/kelompok/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/kelompok/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        
        //Export Excel 
        exportReport(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/kelompok/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        // Export PDF 
        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/kelompok/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getPersentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/kelompok/persentase', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        importFile(_, data){
            return new Promise((resolve, reject) => {
                axios.post('/kelompok/import', data).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
    getters: {

    }

}