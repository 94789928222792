import axios from 'axios';


export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token')
    },
    mutations: {},
    actions: {

        getListBantuan( {state}, _params) {
            const token = state.token
            return new Promise((resolve, reject) => {
                axios.get('/bantuan?row=100&sortby=id&sorttype=desc&keyword=&kecamatan=&desa=', { params: _params, token }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addListBantuan({state}, data) {

            const payload = {
                ...data,
                token: state.token
            }

            console.log(payload)

            return new Promise((resolve, reject) => {
                axios.post('/bantuan/create', payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateListBantuan({state}, { id, data }) {

            const payload = {
                ...data,
                token: state.token
            }

            return new Promise((resolve, reject) => {
                axios.put('/bantuan/update/' + id, payload).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteListBantuan(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/bantuan/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/bantuan/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/bantuan/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/bantuan/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getPersentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/bantuan/persentase', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        
        importFile(_, data){
            return new Promise((resolve, reject) => {
                axios.post('/bantuan/import', data).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
    getters: {

    }

}