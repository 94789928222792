import Vue from 'vue'
import Router from 'vue-router'
// import paketPekerjaan from '../store/module/paket-pekerjaan'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - menu
const Dashboard = () => import('@/views/menu/dashboard')
const Agenda = () => import('@/views/menu/agenda')
const DokumenOPD = () => import('@/views/menu/dokumen-opd')
const DokumenPeraturan = () => import('@/views/menu/dokumen-peraturan')
const Pesan = () => import('@/views/menu/pesan')
const OnlineChat = () => import('@/views/menu/online-chat')
const MasterUser = () => import('@/views/menu/master-user')
const MasterOPD = () => import('@/views/menu/master-opd')
const MasterRole = () => import('@/views/menu/master-role')
const History = () => import('@/views/menu/history')
const Gallery = () => import('@/views/menu/gallery')
const TipeDokumen = () => import('@/views/menu/tipe-dokumen')
const DetailAgenda = () => import('@/views/menu/detail-agenda')
const DokumenKota = () => import('@/views/menu/dokumen-kota')
const LaporanHarian = () => import('@/views/menu/laporan-harian')
const LaporanMasyarakat = () => import('@/views/menu/laporan-masyarakat')
const JadwalPerencanaan = () => import('@/views/menu/jadwal-perencanaan')
const Lp2b = () => import('@/views/menu/lp2b')
const JaringanIrigasi = () => import('@/views/menu/jaringan-irigasi')
const Category = () => import('@/views/menu/category')
const Shp = () => import('@/views/menu/shp')
const IrigasiMacro = () => import('@/views/menu/irigasi-macro')
const KapasitasProduksi = () => import('@/views/menu/kapasitas-produksi')

// Views - Menu -> Master Data
const ListPeternak = () => import('@/views/menu/list-peternak.vue')
const ListKelompok = () => import('@/views/menu/list-kelompok.vue')
const ListJenisHewan = () => import('@/views/menu/jenis-hewan.vue')
const ListBantuan = () => import('@/views/menu/list-bantuan.vue')
const ParameterProduksi = () => import('@/views/menu/parameter-produksi.vue')

// Views - Menu -> Populasi Hewan
const ListPopulasi = () => import('@/views/menu/list-populasi.vue')
const ListProduksi = () => import('@/views/menu/list-produksi.vue')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const ExportPDF = () => import('@/views/menu/components/daily-report-pdf')
const PaketPekerjaanExportPDF = () => import('@/views/menu/components/paket-pekerjaan-pdf')
const ExportPopulasiPdf = () => import('@/views/menu/components/export-populasi-pdf.vue')
const ExportKelompokPdf = () => import('@/views/menu/components/export-kelompok-pdf.vue')
const ExportParameterPdf = () => import('@/views/menu/components/export-parameter-pdf.vue')
const ExportPeternakPdf = () => import('@/views/menu/components/export-peternak-pdf.vue')
const ExportBantuanPdf = () => import('@/views/menu/components/export-bantuan-pdf.vue')
const ExportJenisHewanPdf = () => import('@/views/menu/components/export-jenis-hewan-pdf.vue')
const ExportProduksiPdf = () => import('@/views/menu/components/export-produksi-pdf.vue')
// const PaketPekerjaan = () => import('@/views/menu/paket-pekerjaan')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})



function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        // Menu -> Master Data
        {
          path: '/master-data',
          redirect: '/master-data/list-peternak',
          name: 'Master Data',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list-peternak',
              name: 'List Peternak',
              component: ListPeternak
            },
            {
              path: 'list-kelompok',
              name: 'List Kelompok',
              component: ListKelompok
            },
            {
              path: 'jenis-hewan',
              name: 'Jenis Hewan',
              component: ListJenisHewan
            },
            {
              path: 'list-bantuan',
              name: 'List Bantuan',
              component: ListBantuan
            },
            {
              path: 'parameter-produksi',
              name:'Parameter Produksi',
              component: ParameterProduksi
            }
          ]
        },
        {
          path: 'agenda',
          redirect: '/agenda/list',
          name: 'Agenda',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Agenda list',
              component: Agenda
            },
            {
              path: 'detail',
              name: 'Detail Agenda',
              component: DetailAgenda
            },

          ]
        },
        {
          path: 'history',
          name: 'History',
          component: History
        },
        {
          path: 'populasi-hewan',
          redirect: '/populasi-hewan/list-populasi',
          name: 'Populasi Hewan',
          component: {
            render(c) { return c('router-view')}
          },
          children: [
            {
              path: 'list-populasi',
              name: 'List Populasi',
              component: ListPopulasi
            },
            {
              path: 'list-produksi',
              name: 'List Produksi',
              component: ListProduksi
            }
          ]
        },
        {
          path: 'master-user',
          redirect: '/master-user/user',
          name: 'Master User',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'user',
              name: 'User data',
              component: MasterUser
            },
            {
              path: 'opd',
              name: 'OPD',
              component: MasterOPD
            },
            {
              path: 'role',
              name: 'Role',
              component: MasterRole
            }
          ]
        },
        {
          path: 'laporan-harian',
          name: 'Laporan Harian',
          component: LaporanHarian
        },
        {
          path: 'export-file-pdf',
          name: 'ExportPDF',
          component: ExportPDF
        },
        {
          path: 'export-populasi-pdf',
          name: 'ExportPopulasiPdf',
          component: ExportPopulasiPdf
        },
        {
          path: 'export-kelompok-pdf',
          name: 'ExportKelompokPdf',
          component: ExportKelompokPdf
        },
        {
          path: 'export-parameter-pdf',
          name: 'ExportParameterPdf',
          component: ExportParameterPdf
        },
        {
          path: 'export-bantuan-pdf',
          name: 'ExportBantuanPdf',
          component: ExportBantuanPdf
        },
        {
          path: 'export-jenis-hewan-pdf',
          name: 'ExportJenisHewanPdf',
          component: ExportJenisHewanPdf
        },
        {
          path: 'export-peternak-pdf',
          name: 'ExportPeternakPdf',
          component: ExportPeternakPdf
        },
        {
          path: 'export-produksi-pdf',
          name: 'ExportProduksiPdf',
          component: ExportProduksiPdf
        },
        {
          path: 'paket-pekerjaan-pdf',
          name: 'PaketPekerjaanExportPDF',
          component: PaketPekerjaanExportPDF
        },
        {
          path: 'laporan-masyarakat',
          name: 'Laporan Masyarakat',
          component: LaporanMasyarakat
        },
        {
          path: 'gallery',
          name: 'Gallery',
          component: Gallery
        },
        {
          path: 'data',
          redirect: '/data/dokumen-opd',
          name: 'Data',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'dokumen-opd',
              name: 'Dokumen UPTD',
              component: DokumenOPD
            },
            {
              path: 'peraturan',
              name: 'Dokumen Peraturan',
              component: DokumenPeraturan
            },
            {
              path: 'kota',
              name: 'Dokumen Kota',
              component: DokumenKota
            },
            {
              path: 'tipe-dokumen',
              name: 'Tipe Dokumen',
              component: TipeDokumen
            },
            {
              path: 'jadwal-perencanaan',
              name: 'Jadwal Perencanaan',
              component: JadwalPerencanaan
            }
          ]
        },

        {
          path: 'komunikasi',
          redirect: '/komunikasi/pesan',
          name: 'Komunikasi',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'pesan',
              name: 'Pesan',
              component: Pesan
            },
            {
              path: 'online-chat',
              name: 'Online Chat',
              component: OnlineChat
            }
          ]
        },

        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'lp2b',
          name: 'LP2B',
          component: Lp2b
        },
        {
          path: 'jaringan-irigasi',
          name: 'Jaringan Irigasi',
          component: JaringanIrigasi
        },
        {
          path: 'category',
          name: 'Pengelolaan Category',
          component: Category
        },
        {
          path: 'shp',
          name: 'Pengelolaan Shp',
          component: Shp
        },
        {
          path: 'irigasi-macro',
          name: 'Irigasi Macro',
          component: IrigasiMacro
        },
        {
          path: 'kapasitas-produksi',
          name: 'Kapasitas Produksi',
          component: KapasitasProduksi
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
      ]
    }
  ]
}

